import { Icon } from '#app/components/ui/icon.tsx';
import { Form } from '@remix-run/react';
import { z } from 'zod';

import { Button } from '#app/components/ui/button/button.tsx';
import { isMobile } from 'react-device-detect';

export const GITHUB_PROVIDER_NAME = 'github';
export const GOOGLE_PROVIDER_NAME = 'google'
// to add another provider, set their name here and add it to the providerNames below

export const providerNames = [
  // GITHUB_PROVIDER_NAME,
  GOOGLE_PROVIDER_NAME, 
] as const;
export const ProviderNameSchema = z.enum(providerNames);
export type ProviderName = z.infer<typeof ProviderNameSchema>;

export const providerLabels: Record<ProviderName, string> = {
  // [GITHUB_PROVIDER_NAME]: 'GitHub',
  [GOOGLE_PROVIDER_NAME]: 'Google',
} as const;

export const providerIcons: Record<ProviderName, React.ReactNode> = {
  // [GITHUB_PROVIDER_NAME]: <Icon name="github" />,
  [GOOGLE_PROVIDER_NAME]: <Icon size='xlarge' name="google" />,
} as const;

export function ProviderButton(
  {
    providerName,
  }: {
    providerName: ProviderName,
  }
) {
  const formAction = `/auth/${providerName}`;
  
  const text: Record<ProviderName, string> = {
    // github: 'Github으로 시작하기',
    google: !isMobile ? '구글로 3초만에 시작하기' : '구글로 시작하기',
  }

  return (
    <Form
      style={{display: 'inherit'}}
      action={formAction}
      method="POST"
    >
      <Button
        rounded={'full'} 
        type="submit"
        size={'large'}
      >
        <span className="grid grid-flow-col gap-1.5 text-gray-100 text-label-2-bold xs:font-sfProBold sm:font-pretendardBold items-center">
          {providerIcons[providerName]}
          <span>
            {text[providerName]}
          </span>
        </span>
      </Button>
    </Form>
  )
}